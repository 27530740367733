import { features } from './features'; 

features.applicationType = 'fitosgest';
features.clienteName = 'Productor';
features.clientesName = 'Productores';
features.showEstadisticas = false;
features.showAnalisis = true;
features.showMapas = true;
features.showMapaTratamientos = true;
features.showHistoricoFT = true;
features.showCargaPAC = true;
features.showTractorMarcha = true;
features.showTractorRPM = true;
features.showTractorPresion = true;
features.showTractorBoquillas = true;
features.showVisitasConsultas = true;
features.showVisitasCamposPersonalizados = true;
features.showDesplazamientoObra = true;
features.showVademecumMenu = true;
features.showTratamientosMenu = true;
features.showPartida = true;
features.puedeImprimir = true;
features.showAbonado = true;
features.showRegistroTratamiento = true;
features.showPhCaldo = true;
features.showEnviarWhatsapp = true;
features.showEnviarCorreo = true;
features.showEnviarWhatsappVisitas = true;
features.showEnviarCorreoVisitas = true;
features.showTareasBio = true;
features.showOrdenAplicacionProductos = true;
features.showMeteoBtn = true;
features.showRecetaGlobalGAP = false;
features.adminCanSeeAllRecords = true;
features.showVisitasUploadPhotos = true;
features.showMapasSatelite = false;
features.showAnalisisSectorParcelas = true;
features.showTareasSiembra = true;
features.showRopoRomaRegepa = true;
features.showConsultaRopo = true;
features.showClientesRegepa = true;
features.showClientesCsv = false;
features.showFincasCSV = false;
features.showBotonPrintParcelas = false;
features.showStock = true;
features.showStockTipoDeDato = false;
features.showStockProveedor = false;
features.showStockEnvase = false;
features.showReto = false;
features.showVentusky = true;
features.showFotoTrajador = true;
features.showVentasComercioProveedor = true;
features.showStockProveedorClienteDropdown = false;
features.showMapaGraniot = true;
features.showTareasSiembraProductos = true;
features.showMapaLabels = true;

features.showMotivo = false;
features.showVisitasVariedades = true;
features.hiddenVisitasCerezo = true;
features.hiddenVisitasContador = true;
features.showVisitasFormAbonosFolques = true;
features.showVisitaCarenciasMultiple = true;
features.showDesplazamientoObra = false;

features.showUsuariosLogs = true;
features.hasClients = true;
features.hasCodigoCliente = true;

export const environment = {
    production: true,
    v: '2',
    serverUrl: 'https://prodalbar.fitosgest.com/',
    logoUrl: 'assets/logo_prodalbar.png',
    appName: 'prodalbar',
    features: features
};